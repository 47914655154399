<template>
    <div class="hospital-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">选择医院</span></div>
            <div class="tool-box">
                <el-button class="btn-ok" type="primary" size="mini" @click="selectHospital()">确定</el-button>
            </div>
        </div>
        <div class="hospital-container">
            <el-radio v-model="selectedHospital" :label="hospital.id" v-for="hospital in hospitals" :key="hospital.id">{{hospital.name}}</el-radio>
        </div>
    </div>
</template>

<script>
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'

import { UrlHelper } from '../../utils/Utils'
export default {
    name: 'HospitalListView',
    data() {
        return {
            hospitals: [],
            selectedHospital: ""
        }
    },
    created() {
        // this.hospitals.push({id: "1", name: "沈阳第一医院"});
        // this.hospitals.push({id: "2", name: "医大第一医院"});
        // this.hospitals.push({id: "3", name: "医大第三医院"});
        // history.pushState("", "医院选择列表", "#HospitalList")
        let that = this;
        if (!UrlHelper.parse('hospital')) {
            MessageProxy.getHospitalList({}).then(function(result){

                if (true == result.hasError) {
                    that.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != 0) {
                    that.$message.error(result.data.message || result.data.desc);
                    return;
                }
                that.hospitals = result.data.data;
                store.hospitals = that.hospitals;
            })
        }
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            // this.$router.go(-1);
        },
        selectHospital() {
            // this.$message.info(this.selectedHospital);
            if ("" === this.selectedHospital) {
                this.$message.error("请选择就诊医院！");
                return;
            }
            this.$emit("selectHospital", this.selectedHospital);

            // this.$parent.selectedHospital = this.selectedHospital;
            // console.log("$parent", this.$parent.selectedHospital)
            // this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
    .hospital-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }
    .title-container .tool-box {
        padding: 0 5vmin;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hospital-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;

        font-size: 4vmin;
        text-align: left;
        overflow-y: auto;
    }
    .hospital-container .el-radio {
        display: block;
        background-color: #fff;
        margin-right: 0;
        margin-bottom: 2vmin;
        padding: 3vmin;
        font-size: 4vmin;
    }
    .hospital-container .el-radio .el-radio__label {
        font-size: 4vmin;
    }
</style>